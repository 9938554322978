import { Injectable } from '@angular/core';
import { environment } from 'src/assets/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({ providedIn: 'root', })
export class MonitorService {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights?.instrumentationKey,
        // option to log all route changes, If not needed to lo all route changes, make it false
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        disableCookiesUsage:true
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

}
