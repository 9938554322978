import { NgIterable } from "@angular/core";

export class GlobalConstants {
    public static ackMsgFrench: string = "Ferme";
    public static ackMsg: string = "Ok";
    public static fileNotFound: string = "File not found!";
    public static fileNotFoundFrench: string = "fichier non trouvé!";
    public static oktaRedirectPath = "/home";
    public static oktaScope = "openid offline_access profile";
    public static oktaTimeout = 30 * 60 * 1000; //30 mins in millseconds
    public static buttonColorCode: string = "#005DAB";
    public static COMMUNICATIONS: string = "Communications";
    public static ACRYLICSTANDINSERT: string = "Acrylic_Stand_Insert";
    public static extPath: string = "gmprotection.ca";
    public static inValidUrl = 'https://www.youtube-nocookie.com/embed/NotValidURL';
    public static productTypesEN = [
      {
        key: "EV_Pre-Paid_Maintenance", value: "EV Pre-Paid Maintenance",
      },
      {
        key: "EV_Protection_Plan", value: "EV Protection Plan",
      },
      {
        key: "GAP", value: "GAP",
      },
      {
        key: "Multi_Protect", value: "Multi Protect",
      },
      {
        key: "Prepaid_Maintenance", value: "Pre-Paid Maintenance",
      },
      {
        key: "Protection_Plan",  value: "Protection Plan",
      },
      {
        key: "Tire_Protection", value: "Tire Protection",
      },
      {
        key: "XS_Wear", value: "XS Wear Lease Protection"
      }
    ]
  
    public static productTypesFR = [
      {
        key: "EV_Pre-Paid_Maintenance", value: "Entretien prépayé pour VÉ",
      },
      {
        key: "EV_Protection_Plan", value: "Plan de protection pour VÉ",
      },
      {
        key: "GAP", value: "PEA",
      },
      {
        key: "Multi_Protect", value: "Multi-protection",
      },
      {
        key: "Prepaid_Maintenance", value: "Entretien prépayé",
      },
      {
        key: "Protection_Plan", value: "Plan de protection",
      },
      {
        key: "Tire_Protection", value: "Protection des pneus",
      },
      {
        key: "XS_Wear", value: "Protection usure XS Pour la location"
      }
    ]
  
    public static productTypesPowerUpEN = [
      {
        key: "PowerUp_Protection", value: "PowerUp Protection Plan",
      }
    ]
  
    public static productTypesPowerUpFR = [
      {
        key: "PowerUp_Protection", value: "Plan de Protection PowerUp",
      }
    ]
    public static productTypesPowerUpPlusEN = [
      {
        key: "PowerUp_ProtectionPlus", value: "PowerUp+ Protection Plan",
      }
    ]
 
    public static productTypesPowerUpPlusFR = [
      {
        key: "PowerUp_ProtectionPlus", value: "Plan de Protection PowerUp+",
      }
    ]
    public static productTypesGeneralEN = [
      {
        key: "Bulletins", value: "Bulletins",
      },
      {
        key: "Home_Office_Letter", value: "Home Office Letter",
      },
      {
        key: "Memos", value: "Memos",
      }
    ]
    public static productTypesGeneralFR = [
      {
        key: "Bulletins", value: "Bulletins",
      },
      {
        key: "Home_Office_Letter", value: "Lettre du siège social",
      },
      {
        key: "Memos", value: "Notes",
      }
    ]

      public static mediaTypesEN = [ {
        key: "Acrylic_Stand_Insert",  value: "Acrylic Stand Insert",
      },
      {
        key: "Brochures", value: "Brochures",
      },
      {
        key: "Communications",  value: "Communications",
      },
      {
        key: "Desk_Mats",  value: "Desk Mats",
      },
      {
        key: "Videos", value: "Videos"
      }
    ]
  
    public static mediaTypesFR = [{
      key: "Acrylic_Stand_Insert",  value: "Plaque de support en acrylique",
    },
    {
      key: "Brochures", value: "Brochures",
    },
    {
      key: "Communications",  value: "Communications",
    },
    {
      key: "Desk_Mats",  value: "Tapis de bureau",
    },
    {
      key:"Videos", value:"Vidéos"
    }
  ]

  public static brandTypeDefault = [{
    key: "Buick", value: "Buick",
  },
  {
    key: "Cadillac",  value: "Cadillac",
  },
  {
    key: "Chevrolet",  value: "Chevrolet",
  },
  {
    key: "GMC",  value: "GMC",
  }
]

public static brandTypeBrochures = [{
  key: "Buick", value: "Buick",
},
{
  key: "Cadillac",  value: "Cadillac",
},
{
  key: "Chevrolet",  value: "Chevrolet",
},
{
  key: "GMC",  value: "GMC",
},
{
  key: "PowerUP",  value: "PowerUp",
},
{
  key: "PowerUP+",  value: "PowerUp+",
}
]

public static brandTypeCommunicationsEN = [{
  key: "General", value: "General",
  }
  ]
  
  public static brandTypeCommunicationsFR = [{
    key: "General", value: "Généralités",
    }
    ]

    public static productTypesAcrylicStandEN = [
      {
        key: "General", value: "General",
      },
      {
        key: "Protection_Plan",  value: "Protection Plan",
      }
    ]
    public static productTypesAcrylicStandFR = [
      {
        key: "General", value: "Generalities",
      },
      {
        key: "Protection_Plan", value: "Plan de protection",
      }
    ]

  public static mmTypeLangMap:{[key:string] : NgIterable<any>}={
    "English": GlobalConstants.mediaTypesEN,
    "en":GlobalConstants.mediaTypesEN,
    "French": GlobalConstants.mediaTypesFR
}
}